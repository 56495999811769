import React, { useState, useEffect } from "react";
import "../../App.css";
import { FaCaretDown } from "react-icons/fa";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
// import { MenuIcon, XIcon } from "@heroicons/react/outline";
import {
  BellIcon,
  CogIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  LogoutIcon,
  DocumentTextIcon,
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { logout } from "../../services/auth";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CreateIcon from "@mui/icons-material/Create";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import user from "../../assets/user.png";
import { getUserDetails } from "../../services/loggedInApp";

function ProfileNav() {
  const [userInfo, setUserInfo] = useState({});

  const userDetails = () => {
    getUserDetails().then((res) => {
      setUserInfo(res.data);
    });
  };

  useEffect(() => {
    userDetails();
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="profileNav sticky top-0 z-20">
      <Disclosure as="nav" className="bg-black">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  {/* <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button> */}
                </div>
                <div className="flex-1 flex items-center justify-start sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/apps">
                      {/* <img
                        className="block lg:hidden h-8 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                        alt="Workflow"
                      />
                      <img
                        className="hidden lg:block h-8 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                        alt="Workflow"
                      /> */}
                      <img
                        className="block h-6 sm:h-8 w-auto"
                        src={"/assets/padlock.png"}
                        alt="padlock"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {/* {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))} */}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <button>
                    <span className="sr-only">View notifications</span>
                    <Link to="/notifications">
                      <BellIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                        color="white"
                      />
                    </Link>
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className=" mx-5 flex text-sm rounded-full">
                            <span className="sr-only">Open user menu</span>
                            <p className="w-8 h-8 bg-white rounded-full flex justify-center items-center">
                              <img
                                src={user}
                                alt="User Profile Photo"
                                className="w-6 h-6 rounded-full"
                              />
                            </p>
                            <h2 className="text-md text-white font-bold px-3 py-2">
                              {userInfo.first_name} {userInfo.last_name}
                            </h2>
                            <FaCaretDown
                              className="w-4 h-4 my-2"
                              color="white"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-52 z-10 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/userProfile"
                                  activeclassname="bg-gray-800 text-white"
                                  className={classNames(
                                    active ? "bg-gray-800 text-white" : "",
                                    "block px-4 py-2 text-sm :focus:bg-gray-800"
                                  )}>
                                  {" "}
                                  <AccountCircleIcon
                                    className={classNames("w-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    Profile
                                  </span>
                                </Link>
                              )}
                            </Menu.Item>
                            {/* <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/organizations"
                                  activeclassname="bg-gray-800 text-white"
                                  className={classNames(
                                    active ? "bg-gray-800 text-white" : "",
                                    "block px-4 py-2 text-sm :focus:bg-gray-800"
                                  )}
                                >
                                  {" "}
                                  <CorporateFareIcon
                                    className={classNames("w-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    Organizations
                                  </span>
                                </Link>
                              )}
                            </Menu.Item> */}
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/settings"
                                  activeclassname="bg-gray-800 text-white"
                                  className={classNames(
                                    active ? "bg-gray-800 text-white" : "",
                                    "block px-4 py-2 text-sm :focus:bg-gray-800"
                                  )}>
                                  {" "}
                                  <CogIcon
                                    className={classNames("w-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    Settings
                                  </span>
                                </Link>
                              )}
                            </Menu.Item>
                            {/* <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/plans"
                                  className={classNames(
                                    active ? "bg-gray-800  text-white" : "",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  <CreditCardIcon
                                    className={classNames("w-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    Plan {"&"} Payment
                                  </span>
                                </Link>
                              )}
                            </Menu.Item> */}
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/user-earned-credits"
                                  className={classNames(
                                    active ? "bg-gray-800  text-white" : "",
                                    "block px-4 py-2 text-sm"
                                  )}>
                                  <CreditScoreIcon
                                    className={classNames("w-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    User Earned Credits
                                  </span>
                                </Link>
                              )}
                            </Menu.Item>

                            {/* <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to='/userpage'
                                  className={classNames(
                                    active ? "bg-gray-800  text-white" : "",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  <LogoutIcon
                                    className={classNames("w-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    User Page
                                  </span>
                                </Link>
                              )}
                            </Menu.Item> */}

                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/user"
                                  className={classNames(
                                    active ? "bg-gray-800  text-white" : "",
                                    "block px-4 py-2 text-sm"
                                  )}>
                                  <LogoutIcon
                                    className={classNames("w-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    User
                                  </span>
                                </Link>
                              )}
                            </Menu.Item>

                            {/* <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to='/registeredUser'
                                  className={classNames(
                                    active ? "bg-gray-800  text-white" : "",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  <HowToRegIcon
                                    className={classNames("w-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    Registered Users
                                  </span>
                                </Link>
                              )}
                            </Menu.Item> */}

                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/adminCreditPage"
                                  className={classNames(
                                    active ? "bg-gray-800 text-white" : "",
                                    "block px-4 py-2 text-sm"
                                  )}>
                                  <LogoutIcon
                                    className={classNames("2-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    Admin Credit Page
                                  </span>
                                </Link>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/profile"
                                  className={classNames(
                                    active ? "bg-gray-800 text-white" : "",
                                    "block px-4 py-2 text-sm"
                                  )}>
                                  <CreateIcon
                                    className={classNames("2-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    Create App
                                  </span>
                                </Link>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/doc"
                                  className={classNames(
                                    active ? "bg-gray-800 text-white" : "",
                                    "block px-4 py-2 text-sm"
                                  )}>
                                  <DocumentTextIcon
                                    className={classNames("2-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    API Documentation
                                  </span>
                                </Link>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  onClick={logout}
                                  href="#"
                                  className={classNames(
                                    active ? "bg-gray-800  text-white" : "",
                                    "block px-4 py-2 text-sm"
                                  )}>
                                  <LogoutIcon
                                    className={classNames("w-6 h-6 navIcon")}
                                  />
                                  <span className={classNames("px-3")}>
                                    Log out
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>

            {/* <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </Disclosure.Panel> */}
          </>
        )}
      </Disclosure>
    </div>
  );
}

export default ProfileNav;
