// import React from "react";
import React, { useState } from "react";

const PadlockAuthIntegration = () => {
  const [backend, setBackend] = useState("express"); // Default: Express

  const backendOptions = {
    express: {
      title: "Node.js (Express + Sequelize)",
      model: `// models/User.js
const { DataTypes } = require("sequelize");
const sequelize = require("../database");

const User = sequelize.define("User", {
  id: { type: DataTypes.UUID, primaryKey: true, defaultValue: DataTypes.UUIDV4 },
  user_uuid: { type: DataTypes.UUID, allowNull: false },
  user_id: {type: DataTypes.INTEGER, allowNull: false}
  email: { type: DataTypes.STRING, allowNull: false, unique: true },
  org_name: { type: DataTypes.STRING },
  org_uuid: { type: DataTypes.UUID },
  first_name: { type:DataTypes.STRING, allowNull: false},
  last_name: { type: DataTypes.STRING, allowNull: false},
});

module.exports = User;`,
      route: `
router.post("/register", async (req, res) => {
  const { user_uuid, email, org_name, org_uuid ,user_id,first_name,last_name} = req.body;
  
  try {
    await User.create({ 
    user_uuid,
    email,
    org_name,
    org_uuid,
    user_id,
    first_name,
    last_name,
   });
    res.json({ success: true });
  } catch (err) {
    res.status(500).json({ error: "Error storing user" });
  }
});`,
      middleware: `const jwt = require("jsonwebtoken");

const authenticateToken = (req, res, next) => {
  const token = req.headers["authorization"];
  if (!token) return res.status(401).json({ error: "Access Denied" });

  jwt.verify(token, "PADLOCK_SECRET_KEY", (err, user) => {
    if (err) return res.status(403).json({ error: "Invalid Token" });
    req.user = user;
    next();
  });
};`,
      protectedRoute: `router.get("/protected", authenticateToken, (req, res) => {
  res.json({ message: "Protected route accessed!", user: req.user });
});`,
    },

    flask: {
      title: "Python (flask + SQLAlchemy)",
      model: `# models.py
from flask_sqlalchemy import SQLAlchemy
db = SQLAlchemy()

class User(db.Model):
    id = db.Column(db.Integer, primary_key=True)
    user_uuid = db.Column(db.String(80), nullable=False)
    user_id = db.Column(db.String(80), nullable=False)
    email = db.Column(db.String(120), unique=True, nullable=False)
    org_name = db.Column(db.String(120), nullable=False)
    org_uuid = db.Column(db.String(120), nullable=False)
    first_name = db.Column(db.String(120), nullable=False)
    last_name = db.Column(db.String(120), nullable=False)`,
      route: `# routes.py
from flask import Flask, request, jsonify
from models import db, User

app = Flask(__name__)
app.config['SQLALCHEMY_DATABASE_URI'] = 'sqlite:///users.db'
db.init_app(app)

@app.route('/register', methods=['POST'])
def store_user():
    data = request.json
    user = User(**data)
    db.session.add(user)
    db.session.commit()
    return jsonify({"message": "User stored successfully"})`,
      middleware: `# middleware.py
from flask import request, jsonify
import jwt

SECRET_KEY = "PADLOCK_SECRET_KEY"

def auth_middleware(f):
    def wrapper(*args, **kwargs):
        token = request.headers.get("Authorization")
        if not token:
            return jsonify({"message": "Unauthorized"}), 401
        try:
            decoded = jwt.decode(token.split(" ")[1], SECRET_KEY, algorithms=["HS256"])
            request.user = decoded
        except jwt.ExpiredSignatureError:
            return jsonify({"message": "Token expired"}), 401
        except jwt.InvalidTokenError:
            return jsonify({"message": "Invalid Token"}), 401
        return f(*args, **kwargs)
    return wrapper`,
      protectedRoute: `# routes.py (Add this to routes.py)
from flask import Flask, request, jsonify
from middleware import auth_middleware

app = Flask(__name__)

@app.route('/protected', methods=['GET'])
@auth_middleware
def protected():
    return jsonify({"message": "You have accessed a protected route!"})`,
    },

    //     axum: {
    //       title: "Rust (Axum + SQLx)",
    //       model: `use sqlx::FromRow;

    // #[derive(FromRow)]
    // pub struct User {
    //     pub user_uuid: String,
    //     pub email: String,
    //     pub org_name: Option<String>,
    //     pub org_uuid: Option<String>,
    //     pub first_name: String,
    //     pub last_name: String,
    //     PUB user_id: Option<String>,

    // }`,
    //       route: `async fn store_user(Json(payload): Json<User>) -> impl IntoResponse {
    //     sqlx::query!(
    //         "INSERT INTO users (user_uuid, email, org_name, org_uuid,user_id,first_name,last_name) VALUES ($1, $2, $3, $4)",
    //         payload.user_uuid, payload.email, payload.org_name, payload.org_uuid
    //         payload.user_id,
    //         payload.first_name,
    //         payload.last_name
    //     )
    //     .execute(&db)
    //     .await
    //     .unwrap();
    //     Json(json!({"success": true}))
    // }`,
    //       middleware: `async fn authenticate_token(auth_header: Option<HeaderValue>) -> Result<UserClaims, StatusCode> {
    //     let token = auth_header.ok_or(StatusCode::UNAUTHORIZED)?.to_str().unwrap();
    //     let claims = decode_jwt(token).map_err(|_| StatusCode::FORBIDDEN)?;
    //     Ok(claims)
    // }`,
    //       protectedRoute: `async fn protected_route(auth: Auth) -> impl IntoResponse {
    //     Json(json!({"message": "Protected route accessed!"}))
    // }`,
    //     },
  };

  return (
    <>
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">
          Authentication Integration Guide
        </h1>

        <p>
          This guide explains how to integrate authentication using **Padlock**
          and secure your backend API with token-based authentication.
        </p>

        {/* Step 1 - Create Signup & Login Pages in Frontend */}
        <h2 className="text-xl font-semibold mt-6">
          Step 1: Create Signup & Login Pages (Frontend)
        </h2>
        <p>
          To integrate padlock authentication into your app follow the steps
          below
        </p>

        <div className="flex space-x-4 mb-6 mt-6">
          {Object.keys(backendOptions).map((key) => (
            <button
              key={key}
              onClick={() => setBackend(key)}
              className={`px-4 py-2 rounded-lg font-semibold ${
                backend === key ? "bg-blue-600 text-white" : "bg-gray-200"
              }`}>
              {backendOptions[key].title}
            </button>
          ))}
        </div>

        {/* Display Code for Selected Backend */}
        {/* <h2 className="text-xl font-semibold mt-6">
          {backendOptions[backend].title}
        </h2> */}

        <h3 className="text-lg font-semibold mt-4">
          Step 2: Create a User Model & Store User Data (Backend)
        </h3>
        <pre className="bg-gray-100 p-4 rounded-lg text-sm overflow-x-auto">
          {backendOptions[backend].model}
        </pre>

        <h3 className="text-lg font-semibold mt-4">
          Step 3: Store User API Route
        </h3>
        <pre className="bg-gray-100 p-4 rounded-lg text-sm overflow-x-auto">
          {backendOptions[backend].route}
        </pre>

        <h3 className="text-lg font-semibold mt-4">
          Step 4: Authentication Middleware
        </h3>
        <pre className="bg-gray-100 p-4 rounded-lg text-sm overflow-x-auto">
          {backendOptions[backend].middleware}
        </pre>

        <h3 className="text-lg font-semibold mt-4">Step 5: Protected Route</h3>
        <pre className="bg-gray-100 p-4 rounded-lg text-sm overflow-x-auto">
          {backendOptions[backend].protectedRoute}
        </pre>

        {/* Step 6 - Use APIs in React App */}
        <h2 className="text-xl font-semibold mt-6">
          Step 6: Use These APIs in React App
        </h2>
        <p>Now, let's integrate these APIs in your **React frontend**.</p>

        <h3 className="text-lg font-semibold mt-4">
          1️⃣ Register User (Signup Page)
        </h3>
        <pre className="bg-gray-100 p-4 rounded-lg text-sm overflow-x-auto">
          {`
async function handleSignup(email, password,firstName,lastName,ipAddress) {
  const payload={
   email: email,
   password: password,
   first_name:firstName,
   last_name: lastName,
   org_name: "Your Org",
   org_uuid: "org-1234",
   appName:"app name",
   ip_address:ipAddress,
   role:"member"
   appId:1 // app id
  }
  const response = await fetch("https://api.trypadlock.com/padlockAdmin/user-signup", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  });

  const data = await response.json();
  if (data) {
    const registerResp= await fetch("https://your-backend.com/api/register", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_uuid: data.user_uuid,
        user_id: data.user_id
        email: email,
        org_name: data.org_name,
        org_uuid: data.org_uuid,
        first_name: firstName,
        last_name: lastName,
      }),
    });
    alert("Signup successful!");
  }
}
        `}
        </pre>

        <h3 className="text-lg font-semibold mt-4">
          2️⃣ Email Verification & Organization Selection
        </h3>
        <p>
          Before login, the user enters their email and clicks "Continue". This
          calls an API to fetch `org_name` and `org_uuid`. If multiple
          organizations exist, the user selects one before proceeding to the
          password field.
        </p>

        <pre className="bg-gray-100 p-4 rounded-lg text-sm overflow-x-auto">
          {`
async function handleEmailSubmit(email) {
  const payload={
   email: email,
   appName:"app name",
   appId:1 // app id}
  const response = await fetch("https://api.trypadlock.com/padlockAdmin/organization_require", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  });

  const data = await response.json();
  if (data.list.length === 1) {
    setOrgName(data.details[0].name);
    setOrgUUID(data.details[0].org_uuid);
    setShowPasswordField(true);
  } else if (data.list.length > 1) {
    setOrganizations(data.details); // Store multiple orgs for selection
    setShowOrgSelection(true);
  } else {
    alert("No organization found for this email.");
  }
}
  `}
        </pre>

        <h3 className="text-lg font-semibold mt-4">
          2️⃣B Organization Selection (If Multiple Found)
        </h3>

        <pre className="bg-gray-100 p-4 rounded-lg text-sm overflow-x-auto">
          {`
function handleOrgSelection(selectedOrg) {
  setOrgName(selectedOrg.name);
  setOrgUUID(selectedOrg.org_uuid);
  setShowPasswordField(true); // Proceed to password field after selection
}
  `}
        </pre>

        <h3 className="text-lg font-semibold mt-4">
          3️⃣ Login User & Store Token (Login Page)
        </h3>
        <pre className="bg-gray-100 p-4 rounded-lg text-sm overflow-x-auto">
          {`
async function handleLogin(email, password,orgName,orgUuid) {
   const payload={
   email: email,
   password: password,
   org_name: orgName,
   org_uuid: orgUuid,
   appName:"app name",
   appId:1 // app id
  }
  const response = await fetch("https://padlock-api.com/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  });

  const data = await response.json();
  if (data.token) {
    localStorage.setItem("authToken", data.token);
    localStorage.setItem("refreshToken", data.refresh_token):
    alert("Login successful!");
  } else {
    alert("Invalid credentials");
  }
}
        `}
        </pre>

        <h3 className="text-lg font-semibold mt-4">
          4️⃣ Now in the main page call your backend api using token
        </h3>
        <pre className="bg-gray-100 p-4 rounded-lg text-sm overflow-x-auto">
          {`
async function fetchProtectedData() {
  const token = localStorage.getItem("authToken");
  const response = await fetch("https://your-backend.com/protected-route", {
    method: "GET",
    headers: { "Authorization": "Bearer " + token },
  });

  const data = await response.json();
  console.log("Protected Data:", data);
}
        `}
        </pre>

        {/* Conclusion */}
        <h2 className="text-xl font-semibold mt-6">🎉 Done!</h2>
        <p>
          You have successfully integrated authentication using **Padlock** in a
          **React app**! Users can now **sign up, log in, and access protected
          API routes securely**.
        </p>
      </div>
    </>
  );
};

export default PadlockAuthIntegration;
