import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GTable from "../GTable";
import ProfileNav from "../Profile/ProfileNav";
import { transport } from "../../services/api";

export default function OrgLogs() {
  const history = useHistory();
  const location = useLocation();
  const locState = location.state;

  const [activityLogs, setActivityLogs] = useState([]);

  const fetchActivityLogORG = () => {
    transport({
      url: `logs?org_uuid=${locState.orgUuid}`,
      method: "GET",
    }).then((res) => {
      if (res.data.data == undefined || res.data.data == "") {
        setActivityLogs(undefined);
      } else {
        setActivityLogs(res.data.data);
      }
    });
    // getOrgActivityLog(locState.orgUuid).then((res) => {
    //     console.log('activityLOG', res)
    // })
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  useEffect(() => {
    fetchActivityLogORG();
    const size = Math.floor((window.innerHeight - 450) / 45);
    if (size > 8) {
      setPageSize(size);
    }

    window.addEventListener("resize", () => {
      const size = Math.floor((window.innerHeight - 450) / 45);
      if (size > 8) {
        setPageSize(size);
      }
    });
    return () => {
      window.addEventListener("resize", () => {
        const size = Math.floor((window.innerHeight - 450) / 45);
        if (size > 8) {
          setPageSize(size);
        }
      });
    };
  }, []);

  const displayORGactivity =
    activityLogs == undefined ? (
      <h3 className="text-lg m-10">No Activity Log Found </h3>
    ) : (
      activityLogs
        .slice(pageSize * currentPage, pageSize * (currentPage + 1))
        .map((orgAct, orgactIdx) => {
          return (
            <tr
              key={orgactIdx}
              className={orgactIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {orgAct.created == null ? "" : orgAct.created.slice(0, 10)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {orgAct.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {orgAct.email}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {orgAct.org_name}
              </td>
            </tr>
          );
        })
    );

  return (
    <>
      <ProfileNav />
      <div className="px-10">
        <div className="mt-5">
          <p
            className="flex left-0 top-2 bg-gray-700 rounded-md w-16 h-8 text-white font-bold justify-center items-center shadow-lg cursor-pointer"
            onClick={() => {
              history.goBack();
            }}>
            back
          </p>
        </div>

        <p className="mt-5 text-3xl font-semibold">Activity Logs</p>
        <div className="mt-5">
          {GTable(displayORGactivity, [
            "Created",
            "Description",
            "Email",
            "Org Name",
          ])}
        </div>
        <div className="flex items-center justify-center gap-4 p-4">
          <button
            className="text-white bg-gray-600 disabled:opacity-60 px-4 py-2 rounded"
            disabled={currentPage === 0}
            onClick={() => setCurrentPage(currentPage - 1)}>
            Previous
          </button>
          <button
            className="text-white bg-gray-600 disabled:opacity-60 px-4 py-2 rounded"
            disabled={currentPage * pageSize + pageSize >= activityLogs?.length}
            onClick={() => setCurrentPage(currentPage + 1)}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}
