import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Profile from "./components/Profile/Profile";
import EditScreen from "./screens/EditScreen";
import NotificationScreen from "./screens/NotificationScreen";
import SettingsScreen from "./screens/SettingsScreen";
import PlansAndPayments from "./screens/PlansAndPayments";
import ReferralsAndCredits from "./components/Referrals/ReferralsAndCredits";
import HomePage from "./components/HomePage/HomePage";
import UserEarnedInfo from "./components/userEarnedInfo/UserEarnedInfo";
// import UserPage from "./components/UsersScreen/UserPage";
import User from "./components/UsersScreen/User";
// import RegisteredUsers from "./components/RegisteresUsers/RegisteredUsers";
import MagicLinkPage from "./components/MagicLGenerator/MagicLinkPage";
import AdminCreditPage from "./components/AdminCreditScreen/AdminCreditPage";
import LoginPageScreen from "./components/LoginScreen/LoginPageScreen";
import SignUpPage from "./components/SignUpScreen/SignUp";
import UserProfile from "./components/Profile/UserProfile";
import EachAppPage from "./components/EachAppScreen/EachAppPage";
import PlanPricingSelectionPage from "./components/PlansPayment/PlanPricingSelection";
// import RegionTable from "./components/RegionTable";
// import { PrivateRoute } from "./services/utils/PrivateRoute";
// import { PublicRoute } from "./services/utils/PublicRoute";

import RegionTable from "./components/RegionTable";
import OrgLogs from "./components/OrgLogs/OrgLogs";
// import Organizations from "./components/organization/Organizations";
import RedirectUser from "./components/Redirect";

import { findToken } from "./components/Redirect";
import EachOrgPage from "./components/EachAppScreen/EachAppOrgPage";
import UserORGPage from "./components/EachAppScreen/UserORGPage";
import TeamPage from "./components/Team/TeamPage";
import SendToPay from "./components/setPlanModal/SendToPay";
import PaymentComplete from "./screens/PaymentComplete";
import AppLogs from "./components/OrgLogs/AppLogs";
import OrgPayments from "./screens/OrgPayments";
import { ContextProvider } from "./services/context";
// import DummyDate from "./components/EachAppScreen/dummyBox";
import DocumentationPage from "./components/documentationpage/index";
import GoogleClientDocumentation from "./components/googleClientDoc";
function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !findToken() ? <Component {...props} /> : <Redirect to="/apps" />
      }
    />
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

function App() {
  findToken();

  // if(sessionStorage.getItem('loggin') == null){
  //   localStorage.clear();
  // }
  return (
    <div className="App">
      <ContextProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              {findToken() ? <Redirect to="/apps" /> : <HomePage />}
            </Route>
            <Route
              path="/doc"
              render={(props) => (
                <DocumentationPage {...props} isLoggedin={findToken()} />
              )}
            />
            <Route
              path="/googleClientDoc"
              render={(props) => (
                <GoogleClientDocumentation
                  {...props}
                  isLoggedin={findToken()}
                />
              )}
            />
            <Route path="/payment_complete" component={PaymentComplete} />
            <Route path="/redirect" component={RedirectUser} />
            <PublicRoute path="/loginpage" component={LoginPageScreen} />
            <PublicRoute path="/signup" component={SignUpPage} />

            <Route
              path="/PlanPricingSelection"
              component={PlanPricingSelectionPage}
            />
            <Route path="/:appName/plans" component={PlansAndPayments} />
            <Route
              path="/:appName/payment-history"
              component={PlansAndPayments}
            />
            <Route
              path="/:appName/:orgName/team:teamName"
              component={TeamPage}
            />
            <Route path="/:appName/:orgName/activitylog" component={OrgLogs} />
            <Route path="/:appName/:orgName/payments" component={OrgPayments} />
            <Route path="/:appName/activitylog" component={AppLogs} />
            <Route
              path="/:appName/:orgName/magicLink"
              component={MagicLinkPage}
            />
            <Route
              path="/:appName/:orgName/:username"
              component={UserORGPage}
            />

            {/* <Route path="/profile" ><Profile /></Route> */}
            <Route path="/userProfile" component={UserProfile} />
            <Route path="/:appName/region" component={RegionTable} />
            <Route path="/:appName/:orgName" component={EachOrgPage} />
            <Route path="/profile" component={Profile} />
            <Route path="/edit" Component={EditScreen} />
            {/* <Route path='/:appName/:orgName/:orgUser' compoment={EachUserPage} /> */}
            {/* <Route path="/edit" ><EditScreen /></Route> */}
            <Route path="/notifications" component={NotificationScreen} />
            <Route path="/settings" component={SettingsScreen} />
            <Route
              path="/:appName/referrals-and-credits"
              component={ReferralsAndCredits}
            />
            <Route path="/user-earned-credits" component={UserEarnedInfo} />
            {/* <Route path="/userpage" component={UserPage} /> */}
            <Route path="/apps" component={User} />
            {/* <Route path='/registeredUser' component={RegisteredUsers} /> */}
            <Route path="/adminCreditPage" component={AdminCreditPage} />
            <Route path="/:appName" component={EachAppPage} />
            {/* <PrivateRoute path="/dummy/data/data" component={DummyDate} /> */}
            {/* <PrivateRoute path='/organizations' component={Organizations} /> */}
          </Switch>
        </Router>
      </ContextProvider>
    </div>
  );
}

export default App;
