/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";

import { Link, NavLink } from "react-router-dom";
import Logo from "../../../public/assets/padlock.svg";

const navigation = [
  { name: "Home", to: "/" },
  { name: "Market Place", to: "/plans" },
  { name: "Pricing", to: "/PlanPricingSelection" },
  { name: "About", to: "#" },
  { name: "Documentation", to: "/doc" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  return (
    <Popover className="sticky top-0 z-20 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-5">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="#">
              {/* <span className="sr-only">Workflow</span> */}
              <img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
            </a>
          </div>
          <div className="flex w-auto items-center">
            <div className="-mr-4 -my-2 md:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <div className="flex">
              <Popover.Group as="nav" className="hidden md:flex lg:mr-96">
                <div className="hidden ml-10 md:flex  lg:block mr-2">
                  {navigation.map((link) => (
                    <Link
                      key={link.name}
                      to={link.to}
                      className="text-base sm:font-sm  md:font-medium sm:text-white  hover:text-indigo-50 px-3">
                      {link.name}
                    </Link>
                  ))}
                </div>
              </Popover.Group>
            </div>
            <div className="hidden md:flex items-center justify-end md:flex-1 ml-3 lg:w-0">
              <a
                href="/signup"
                className="ml-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 sm:px-2 border border-transparent rounded-md shadow-sm text-base font-medium border-white text-white">
                Free for 15 days
              </a>
              <a
                href="/signup"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-black bg-white">
                Sign up
              </a>
              <p className="text-white border border-white bg-white h-12 ml-2"></p>
              <a
                // href="/loginpage"
                href="/redirect"
                className="ml-2 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-black bg-white">
                Sign in
              </a>
            </div>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95">
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={Logo} alt="Workflow" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {navigation.map((link) => (
                    <Link
                      key={link.name}
                      to={link.to}
                      className="text-base font-medium text-white hover:text-indigo-50">
                      {link.name}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                <NavLink
                  to="/signup"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                  Sign Up
                </NavLink>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?{" "}
                  <NavLink
                    to="/loginpage"
                    className="text-indigo-600 hover:text-indigo-500">
                    Sign In
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

{
  /* <a
                  href="#"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Sign up
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?{" "}
                  <a href="#" className="text-indigo-600 hover:text-indigo-500">
                    Sign in
                  </a>
                </p> */
}

// import React from "react";
// import { Link } from "react-router-dom";
// import Logo from "../../../public/assets/padlock.svg";

// const navigation = [
//   { name: "Home", to: "#" },
//   { name: "Market Place", to:"/plans" },
//   { name: "Pricing", to: "#" },
//   { name: "About", to: "#" },
// ];

// export default function Navbar() {
//   return (
//     <header className="bg-black">
//       <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
//         <div className="w-full py-6 flex items-center justify-between border-b border-indigo-500 lg:border-none">
//           <div className="flex items-center">
//             <a href="#">
//               <span className="sr-only">Workflow</span>
//               <img className="h-10 w-auto" src={Logo} alt="" />
//             </a>

//           </div>
//           <div className="ml-10 space-x-4 flex items-center">
//           <div className="hidden ml-10 space-x-8 lg:block mr-2">
//               {navigation.map((link) => (
//                 <Link
//                   key={link.name}
//                   to={link.to}
//                   className="text-base font-medium text-white hover:text-indigo-50"
//                 >
//                   {link.name}
//                 </Link>
//               ))}
//             </div>
//             <a
//               href="#"
//               className="inline-block bg-black py-2 px-4 border border-white rounded-md text-base font-medium text-white hover:bg-opacity-75"
//             >
//               FREE for 15 days
//             </a>
//             <a
//               href="#"
//               className="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-black hover:bg-indigo-50"
//             >
//               Sign up
//             </a>
//           </div>
//         </div>
//         <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
//           {navigation.map((link) => (
//             <a
//               key={link.name}
//               href={link.href}
//               className="text-base font-medium text-white hover:text-indigo-50"
//             >
//               {link.name}
//             </a>
//           ))}
//         </div>
//       </nav>
//     </header>
//   );
// }
